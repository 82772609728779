<template>
  <div>
    <div class="maintitle">员工列表</div>
    <el-divider></el-divider>
    <el-row class="text-left" :gutter="10" v-if='srchCondition.length>0'>
      <el-col :span='4'>
        <el-input v-model="keyword" v-if='srchCondition.indexOf("keyword")>=0' placeholder="请输入搜索关键词" size="mini"></el-input>
      </el-col>
      <!-- <el-date-picker style='width:120px;' v-if='srchCondition.indexOf("ruzhi")>=0' v-model="ruzhi_month" type="month" size="mini" class='margin-right' format="yyyy-MM" placeholder="请选择入职"></el-date-picker> -->
      <el-select v-model='ruzhiSYear' clearable class='margin-right' style='width:120px' size='mini' placeholder="入职开始年份" filterable v-if='srchCondition.indexOf("ruzhi")>=0'>
        <el-option v-for="(item) in yearlist" :key='item.id' :value='item.id' :label='item.title'></el-option>
      </el-select>
      <el-select v-model='ruzhiSMonth' clearable class='margin-right' style='width:120px' size='mini' placeholder="入职开始月份" v-if='srchCondition.indexOf("ruzhi")>=0'>
        <el-option v-for="(item) in monlist" :key='item.id' :value='item.id' :label='item.title'></el-option>
      </el-select>
      <el-select v-model='ruzhiEYear' clearable class='margin-right' style='width:120px' size='mini' placeholder="入职结束年份" filterable v-if='srchCondition.indexOf("ruzhi")>=0 && ruzhiSYear!= ""'>
        <el-option v-for="(item) in yearlist" :key='item.id' :value='item.id' :label='item.title'></el-option>
      </el-select>
      <el-select v-model='ruzhiEMonth' clearable class='margin-right' style='width:120px' size='mini' placeholder="入职结束月份" v-if='srchCondition.indexOf("ruzhi")>=0 && ruzhiSYear!= ""'>
        <el-option v-for="(item) in monlist" :key='item.id' :value='item.id' :label='item.title'></el-option>
      </el-select>
      <el-select v-model='zhuanzhengSYear' clearable class='margin-right' style='width:120px' size='mini' placeholder="转正开始年份" filterable v-if='srchCondition.indexOf("zhuanzheng")>=0'>
        <el-option v-for="(item) in yearlist" :key='item.id' :value='item.id' :label='item.title'></el-option>
      </el-select>
      <el-select v-model='zhuanzhengSMonth' clearable class='margin-right' style='width:120px' size='mini' placeholder="转正开始月份" v-if='srchCondition.indexOf("zhuanzheng")>=0'>
        <el-option v-for="(item) in monlist" :key='item.id' :value='item.id' :label='item.title'></el-option>
      </el-select>
      <el-select v-model='zhuanzhengEYear' clearable class='margin-right' style='width:120px' size='mini' placeholder="转正结束年份" filterable v-if='srchCondition.indexOf("zhuanzheng")>=0 && zhuanzhengSYear!= ""'>
        <el-option v-for="(item) in yearlist" :key='item.id' :value='item.id' :label='item.title'></el-option>
      </el-select>
      <el-select v-model='zhuanzhengEMonth' clearable class='margin-right' style='width:120px' size='mini' placeholder="转正结束月份" v-if='srchCondition.indexOf("zhuanzheng")>=0 && zhuanzhengSYear!= ""'>
        <el-option v-for="(item) in monlist" :key='item.id' :value='item.id' :label='item.title'></el-option>
      </el-select>
      <el-select v-model='hetong_month' clearable class='margin-right' style='width:120px' size='mini' placeholder="请选择续签月份" v-if='srchCondition.indexOf("hetong")>=0'>
        <el-option v-for="(item) in monlist" :key='item.id' :value='item.id' :label='item.title'></el-option>
      </el-select>
      <el-select v-model='birthday_month' clearable class='margin-right' style='width:120px' size='mini' placeholder="请选择生日月份" v-if='srchCondition.indexOf("birthday")>=0'>
        <el-option v-for="(item) in monlist" :key='item.id' :value='item.id' :label='item.title'></el-option>
      </el-select>
      <el-select v-model='ruzhiSYear' clearable class='margin-right' style='width:120px' size='mini' placeholder="入职开始年份" filterable v-if='srchCondition.indexOf("ruzhi")>=0'>
        <el-option v-for="(item) in yearlist" :key='item.id' :value='item.id' :label='item.title'></el-option>
      </el-select>
      <el-col :span="4" v-if='srchCondition.indexOf("age")>=0'>
        <div style="width:45%;display:inline-block">
          <el-input v-model="startage" placeholder="起始年龄" size="mini"></el-input>
        </div>
        到
        <div style="width:45%;display:inline-block">
          <el-input v-model="endage" placeholder="结束年龄" size="mini"></el-input>
        </div>
      </el-col>
      <el-select v-model='sex' style='width:120px;' v-if='srchCondition.indexOf("sex")>=0' size="mini" class="margin-right" placeholder="请选择性别">
        <el-option label="男" value="1"></el-option>
        <el-option label="女" value="2"></el-option>
      </el-select>
      <el-cascader v-model="slctDepartment" placeholder="请选择部门" :props="{ checkStrictly: true }" :show-all-levels="false" :options="departmentlist" style='width:120px;' v-if='srchCondition.indexOf("department")>=0' size="mini" class="margin-right"></el-cascader>
    </el-row>
    <el-row class="text-left">
      <el-select size="mini" style='width:400px' v-model="srchCondition" multiple placeholder="请选择筛选条件" class="margin-right" @change="list=[];total=0">
        <el-option v-for='(item,index) in conditionlist' :key='index' :value="item.value" :label='item.label'></el-option>
      </el-select>
      <el-select v-model='slctStatus' style='width:120px;' size="mini" class="margin-right" @change="resetCondition">
        <el-option :value='0' label='未转正'></el-option>
        <el-option :value='1' label='已转正'></el-option>
        <el-option :value='2' label='所有在职'></el-option>
        <el-option :value='9' label='离职'></el-option>
      </el-select>
      <el-button class="margin-right" size="mini" @click="srchOperator">查找</el-button>
      <el-button type="success" size="mini" @click="addDiv=true">添加员工</el-button>
      <el-button type="success" size="mini" @click="outList">导出</el-button>
    </el-row>
    <el-table :data="list" row-key="menuid" border>
      <el-table-column prop="account" label="账号" width='150'></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="tel" label="电话" width='110'></el-table-column>
      <el-table-column prop="department_name" label="部门" v-if='slctStatus!=9'></el-table-column>
      <el-table-column prop="ruzhi_time" label="入职日期" width='100' v-if="srchCondition.indexOf('ruzhi')>=0 && slctStatus!=9"></el-table-column>
      <!-- <el-table-column prop="ruzhiyears" label="入职年数" v-if="srchCondition.indexOf('ruzhi')>=0"></el-table-column> -->
      <el-table-column prop="zhuanzheng_time" label="转正日期" v-if="srchCondition.indexOf('zhuanzheng')>=0 && slctStatus!=9"></el-table-column>
      <el-table-column prop="WorkingYears" label="工龄(年)" v-if='slctStatus!=9'></el-table-column>
      <el-table-column label="生日" v-if="srchCondition.indexOf('birthday')>=0 && slctStatus!=9">
        <template slot-scope="scope">
          <span v-html='scope.row.birthday'></span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="age" label="年龄"></el-table-column> -->
      <el-table-column label="状态" :formatter="formatStatus" width='80' v-if='slctStatus!=9'></el-table-column>
      <el-table-column label="操作" width='280'>
        <template slot-scope="scope">
          <el-button-group v-if='scope.row.status != 9'>
            <el-button type='text' class="margin-right" @click="mdfy(scope)" v-if='slctStatus!=9'>修改</el-button>
            <el-button type='text' class="margin-right" @click="resetpswd(scope)" v-if='slctStatus!=9'>重置密码</el-button>
            <el-button type='text' class="margin-right" @click="resume(scope)" v-if='slctStatus==9'>复职</el-button>
            <el-button type='text' class="margin-right" @click="fire(scope)" v-else>离职</el-button>
            <el-button type='text' class="margin-right" @click="setStatus(scope)" v-if="scope.row.status == 0 && slctStatus!=9">转正</el-button>
            <el-button type='text' class="margin-right" @click="setStatus(scope)" v-if="scope.row.status == 1 && slctStatus!=9">试用</el-button>
            <el-button type='text' class="margin-right" @click="showData(scope)">文件</el-button>
          </el-button-group>
          <el-button-group v-else>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-dialog :visible.sync="addDiv" :show-close="false" :close-on-click-modal="false" @closed="resetFormInfo">
      <el-form :model="employeeinfo">
        <el-form-item label="账号" :label-width="formLabelWidth" prop="account" v-if='employeeinfo.uuid == ""'>
          <el-input v-model="employeeinfo.account" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
          <el-input v-model="employeeinfo.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话" :label-width="formLabelWidth" prop="tel">
          <el-input v-model="employeeinfo.tel" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="所在部门" :label-width="formLabelWidth" class="text-left">
          <div class="block" placeholder='请选择所在部门'>
            <el-cascader v-model="employeeinfo.department" :props="{ checkStrictly: true }" :show-all-levels="false" :options="departmentlist"></el-cascader>
          </div>
        </el-form-item>
        <el-form-item v-for='(item) in sectionlist' :key='item.name' :label-width="formLabelWidth" :label="item.title" class="text-left">
          <el-radio-group v-model="item.auth">
            <el-radio-button label="1">使用</el-radio-button>
            <el-radio-button label="0">不使用</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="职位" :label-width="formLabelWidth" class="text-left" prop="level">
          <el-select v-model="employeeinfo.level" placeholder="请选择">
            <el-option v-for="(item,index) in levellist" :key="index" :label="item.title" :value="item.level"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="性别" class="text-left" prop="sex">
          <el-radio-group v-model="employeeinfo.sex">
            <el-radio-button label="1">男</el-radio-button>
            <el-radio-button label="2">女</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="身份证号" :label-width="formLabelWidth" prop="idcard_num">
          <el-input v-model="employeeinfo.idcard_num" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="入职日期" class="text-left" :label-width="formLabelWidth" prop="ruzhi_time">
          <el-date-picker v-model="employeeinfo.ruzhi_time" type="date" placeholder="选择入职日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="转正日期" class="text-left" :label-width="formLabelWidth" prop="zhuanzheng_time">
          <el-date-picker v-model="employeeinfo.zhuanzheng_time" type="date" placeholder="选择转正日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="缴纳保险月份" class="text-left" :label-width="formLabelWidth" prop="baoxian_time">
          <el-date-picker v-model="employeeinfo.baoxian_time" type="month" placeholder="选择缴纳保险月份">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="合同续签日期" class="text-left" :label-width="formLabelWidth" prop="hetong_time">
          <el-date-picker v-model="employeeinfo.hetong_time" type="date" placeholder="选择合同续签日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="生日类型" class="text-left" prop="birthday_type">
          <el-radio-group v-model="employeeinfo.birthday_type">
            <el-radio-button label="1">公历</el-radio-button>
            <el-radio-button label="2">农历</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="生日月份" :label-width="formLabelWidth" class="text-left" prop="birthdaymonth">
          <el-select v-model="employeeinfo.birthdaymonth" placeholder="请选择">
            <el-option v-for="(item,index) in monthlist" :key="index" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生日日期" :label-width="formLabelWidth" class="text-left" prop="birthdayday">
          <el-select v-model="employeeinfo.birthdayday" placeholder="请选择">
            <el-option v-for="(item,index) in daylist" :key="index" :label="item.title" :value="item.day"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="紧急联系人" :label-width="formLabelWidth" prop="contact_name">
          <el-input v-model="employeeinfo.contact_name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" :label-width="formLabelWidth" prop="contact_tel">
          <el-input v-model="employeeinfo.contact_tel" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="关系" :label-width="formLabelWidth" prop="contact_relation">
          <el-input v-model="employeeinfo.contact_relation" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="employeeinfo.uuid==''">
          新用户默认密码：{{dfltpswd}}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDiv = false">取 消</el-button>
        <el-button type="primary" @click="submdfy">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="picDiv" :title='crntEmployee' :close-on-click-modal="false">
      <el-upload class="upload-demo margin-bottom" :action="HOST + '/admin/employee/uppic'" name="pic" :multiple="true" :data="uploadData" :show-file-list="false" :on-success="setPic" :auto-upload='true'>
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
      </el-upload>
      <div class='piclist'>
        <li v-for='(item,index) in fileList' :key='index'>
          <img :src="PicPath+item" title="点击看大图" @click="showFullPic(PicPath+item)">
          <div class="title">
            <span class="margin-right">{{item}}</span>
            <el-button type='text' @click="delPic(item,index)">删除</el-button>
          </div>
          <!-- <div class="delbtn" @click="delPic(item,index)">删除</div> -->
        </li>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="picDiv = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      srchCondition: [],
      list: [], //员工列表
      groups: [], //用户组列表
      menus: {}, //菜单列表
      keyword: '',
      slctStatus: 2,
      slctDepartment: '',
      slctLevel: '',
      page: 1,
      total: 0,
      addDiv: false,
      tempInfo: {},
      employeeinfo: {
        uuid: "",
        account: "",
        tel: "",
        name: "",
        status: 1,
        level: '',
        sex: 1,
        idcard_num: '',
        ruzhi_time: '',
        zhuanzheng_time: '',
        hetong_time: '',
        birthday: '',
        birthday_type: 1,
        birthdaymonth: '',
        birthdayday: '',
        department: '',
        contact_name: '',
        contact_tel: '',
        contact_relation: '',
        issales: 1,
        baoxian_time:''
      },
      birthday_month: '',
      ruzhiSYear: '',
      ruzhiSMonth: '',
      ruzhiEYear: '',
      ruzhiEMonth: '',
      zhuanzhengSYear: '',
      zhuanzhengSMonth: '',
      zhuanzhengEYear: '',
      zhuanzhengEMonth: '',
      hetong_month:'',
      startage:'',
      endage:'',
      sex:'',
      crntscope: "",
      dfltpswd: '',
      levellist: [],
      departmentlist: [],
      picDiv: false,
      crntEmployee: '',
      fileList: [],
      uploadData: {
        adminuuid: sessionStorage.getItem("adminuuid"),
        admintoken: sessionStorage.getItem("admintoken"),
      },
      sectionlist: []
    };
  },
  computed: {
    PicPath() {
      return this.HOST + '/storage/' + this.uploadData.uuid + '/';
    },
    conditionlist() {
      if (this.slctStatus == 9) { // 搜索离职人员
        return [
          {
            value: 'keyword',
            label: '关键字'
          },
          {
            value: 'department',
            label: '部门'
          },
        ]
      } else {
        return [
          {
            value: 'keyword',
            label: '关键字'
          },
          {
            value: 'ruzhi',
            label: '入职时间'
          },
          {
            value: 'zhuanzheng',
            label: '转正时间'
          },
          {
            value: 'hetong',
            label: '合同续签日期'
          },
          {
            value: 'birthday',
            label: '生日'
          },
          {
            value: 'level',
            label: '职位'
          },
          {
            value: 'department',
            label: '部门'
          },
          {
            value: 'age',
            label: '年龄'
          },
          {
            value: 'sex',
            label: '性别'
          },
        ]
      }
    },
    yearlist() {
      let temp = [];
      for (let i = 2003; i < 2042; i++) {
        temp.push({
          id: i,
          title: i + '年'
        })
      }
      return temp;
    },
    monlist() {
      let temp = [];
      for (let i = 1; i <= 12; i++) {
        temp.push({
          id: i,
          title: i + '月'
        })
      }
      return temp;
    },
    monthlist() {
      if (this.employeeinfo.birthday_type == 1) {
        return this.monlist;
      } else {
        return [
          {
            id: 1,
            title: '正月'
          },
          {
            id: 2,
            title: '二月'
          },
          {
            id: 3,
            title: '三月'
          },
          {
            id: 4,
            title: '四月'
          },
          {
            id: 5,
            title: '五月'
          },
          {
            id: 6,
            title: '六月'
          },
          {
            id: 7,
            title: '七月'
          },
          {
            id: 8,
            title: '八月'
          },
          {
            id: 9,
            title: '九月'
          },
          {
            id: 10,
            title: '十月'
          },
          {
            id: 11,
            title: '冬月'
          },
          {
            id: 12,
            title: '腊月'
          },
        ]
      }
    },
    daylist() {
      if (this.employeeinfo.birthday_type == 1) {
        let daynum = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        let days = daynum.splice(this.employeeinfo.birthdaymonth - 1, 1);
        let temp = [];
        for (let i = 1; i <= days[0]; i++) {
          temp.push({
            day: i,
            title: i + '号'
          })
        }
        return temp;
      } else {
        return [
          {
            day: 1,
            title: '初一'
          },
          {
            day: 2,
            title: '初二'
          },
          {
            day: 3,
            title: '初三'
          },
          {
            day: 4,
            title: '初四'
          },
          {
            day: 5,
            title: '初五'
          },
          {
            day: 6,
            title: '初六'
          },
          {
            day: 7,
            title: '初七'
          },
          {
            day: 8,
            title: '初八'
          },
          {
            day: 9,
            title: '初九'
          },
          {
            day: 10,
            title: '初十'
          },
          {
            day: 11,
            title: '十一'
          },
          {
            day: 12,
            title: '十二'
          },
          {
            day: 13,
            title: '十三'
          },
          {
            day: 14,
            title: '十四'
          },
          {
            day: 15,
            title: '十五'
          },
          {
            day: 16,
            title: '十六'
          },
          {
            day: 17,
            title: '十七'
          },
          {
            day: 18,
            title: '十八'
          },
          {
            day: 19,
            title: '十九'
          },
          {
            day: 20,
            title: '二十'
          },
          {
            day: 21,
            title: '廿一'
          },
          {
            day: 22,
            title: '廿二'
          },
          {
            day: 23,
            title: '廿三'
          },
          {
            day: 24,
            title: '廿四'
          },
          {
            day: 25,
            title: '廿五'
          },
          {
            day: 26,
            title: '廿六'
          },
          {
            day: 27,
            title: '廿七'
          },
          {
            day: 28,
            title: '廿八'
          },
          {
            day: 29,
            title: '廿九'
          },
          {
            day: 30,
            title: '三十'
          },
        ]
      }
    }
  },
  methods: {
    resetCondition() {
      if (this.slctStatus == 9) { // 搜索离职人员
        let arr = ['ruzhi', 'zhuanzheng', 'birthday', 'level'];
        for (let i in arr) {
          if (this.srchCondition.indexOf(arr[i]) != -1) {
            this.srchCondition.splice(this.srchCondition.indexOf(arr[i]), 1);
          }
        }
      }
      this.list = [];
      this.total = 0;
    },
    getlist() {
      this.$axios({
        url: this.HOST + "/admin/employee/list",
        method: "post",
        data: {
          keyword: this.keyword,
          ruzhiSYear: this.ruzhiSYear,
          ruzhiSMonth: this.ruzhiSMonth,
          ruzhiEYear: this.ruzhiEYear,
          ruzhiEMonth: this.ruzhiEMonth,
          zhuanzhengSYear: this.zhuanzhengSYear,
          zhuanzhengSMonth: this.zhuanzhengSMonth,
          zhuanzhengEYear: this.zhuanzhengEYear,
          zhuanzhengEMonth: this.zhuanzhengEMonth,
          birthday_month: this.birthday_month,
          department: this.slctDepartment,
          level: this.slctLevel,
          startage:this.startage,
          endage:this.endage,
          sex:this.sex,
          status: this.slctStatus,
          srchcondition: this.srchCondition,
          hetong_month:this.hetong_month,
          page: this.page,
          pagesize: this.pagesize
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
          this.groups = res.data.groups ? res.data.groups : [];
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    formatStatus(row, column) {
      return row.status == 1 ? "已转正" : "试用期";
    },
    setStatus(scope) {
      let status = scope.row.status == 1 ? 0 : 1;
      this.$axios({
        url: this.HOST + "/admin/employee/setStatus",
        method: "post",
        data: {
          info: {
            uuid: scope.row.uuid,
            status: status
          }
        }
      }).then(res => {
        if (res.data.status == 1) {
          scope.row.status = status;
          this.$message({
            showClose: true,
            type: 'success',
            message: res.data.info
          })
        }
      });
    },
    fire(scope) {
      this.$confirm(
        '确定' + scope.row.name + '离职吗？',
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/admin/employee/fire",
          method: "post",
          data: {
            uuid: scope.row.uuid,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.list.splice(scope.$index, 1);
            this.$message({
              showClose: true,
              type: "info",
              message: "操作成功",
            });
          }
        });
      });
    },
    resume(scope) {
      this.$confirm(
        '确定' + scope.row.name + '复职吗？',
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/admin/employee/resume",
          method: "post",
          data: {
            uuid: scope.row.uuid,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.list.splice(scope.$index, 1);
            this.$message({
              showClose: true,
              type: "info",
              message: "操作成功",
            });
          }
        });
      });
    },
    //重置菜单编辑对话框内容
    resetFormInfo() {
      for (let i in this.employeeinfo) {
        this.employeeinfo[i] = this.tempInfo[i];
      }
      this.crntscope = "";
    },
    mdfy(scope) {
      this.crntscope = scope;
      this.$axios({
        url: this.HOST + '/admin/employee/getinfo',
        method: 'post',
        data: {
          uuid: scope.row.uuid
        }
      }).then(res => {
        if (res.data.status == 1) {
          for (let item in this.employeeinfo) {
            // if (item == 'birthdaymonth')
            this.employeeinfo[item] = res.data.info[item];
          }
          this.addDiv = true;
          this.sectionlist = res.data.section;
        }
      })

    },
    submdfy() {
      this.$axios({
        url: this.HOST + "/admin/employee/add",
        method: "post",
        data: {
          info: this.employeeinfo,
          sectionlist: this.sectionlist
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.addDiv = false;
          this.getlist();
        }
      });
    },
    resetpswd(scope) {
      let uuid = scope.row.uuid;
      this.$confirm(
        "确定要重置" + scope.row.name + "的密码吗？本操作不可恢复",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/admin/employee/resetpswd",
          method: "post",
          data: {
            muuid: uuid,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.$alert(
              "用户密码已重置为 " +
              res.data.newpassword +
              " ，请记录后再关闭本对话框，本密码不可重现！",
              "友情提示",
              {
                confirmButtonText: "确定",
              }
            );
          }
        });
      });
    },
    srchOperator() {
      this.page = 1;
      this.getlist();
    },
    getCommonInfo() {
      this.$axios({
        url: this.HOST + '/admin/employee/getCommonInfo',
        method: 'post',
        data: {
          info: ['department', 'level', 'dfltpswd','section']
        }
      }).then(res => {
        this.departmentlist = res.data.departmentlist;
        this.levellist = res.data.levellist;
        this.dfltpswd = res.data.dfltpswd;
        this.sectionlist = res.data.section;
      })
    },
    showData(scope) {
      this.$axios({
        url: this.HOST + '/admin/employee/getData',
        method: 'post',
        data: {
          uuid: scope.row.uuid
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.fileList = res.data.list;
          this.uploadData.uuid = scope.row.uuid;
          this.crntEmployee = scope.row.name;
          this.picDiv = true;
        }
      })
    },
    setPic(res, file, filelist) {
      if (res.status == 1) {
        if (this.fileList.indexOf(res.savename) == -1)
          this.fileList.push(res.savename);
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.info
        })
      }
    },
    delPic(str, index) {
      this.$confirm(
        '确定要删除图片吗？本操作会同步操作子部门并且不可恢复！！！',
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + '/admin/employee/delpic',
          method: 'post',
          data: {
            item: str,
            uuid: this.uploadData.uuid
          }
        }).then(res => {
          this.fileList.splice(index, 1);
        })
      });
    },
    showFullPic(str) {
      window.open(str);
    },
    outList() {
      this.$axios({
        url: this.HOST + "/admin/employee/outlist",
        method: "post",
        data: {
          keyword: this.keyword,
          ruzhiSYear: this.ruzhiSYear,
          ruzhiSMonth: this.ruzhiSMonth,
          ruzhiEYear: this.ruzhiEYear,
          ruzhiEMonth: this.ruzhiEMonth,
          zhuanzhengSYear: this.zhuanzhengSYear,
          zhuanzhengSMonth: this.zhuanzhengSMonth,
          zhuanzhengEYear: this.zhuanzhengEYear,
          zhuanzhengEMonth: this.zhuanzhengEMonth,
          birthday_month: this.birthday_month,
          department: this.slctDepartment,
          startage:this.startage,
          endage:this.endage,
          sex:this.sex,
          level: this.slctLevel,
          status: this.slctStatus,
          srchcondition: this.srchCondition,
        },
        responseType: "blob"
      }).then(res => {
        let blob = new Blob([res.data]);
        let fileName = '考核详情.csv';
        if ("download" in document.createElement("a")) {
          // 不是IE浏览器
          let url = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(url); // 释放掉blob对象
        } else {
          // IE 10+
          window.navigator.msSaveBlob(blob, fileName);
        }
      });
    },
  },
  created: function () {
    this.getCommonInfo();
    this.getlist();
    for (let i in this.employeeinfo) {
      this.tempInfo[i] = this.employeeinfo[i];
    }
  }
};
</script>
<style>
.piclist {
  width: 100%;
}
.piclist > li {
  list-style: none;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px;
}
.piclist > li > img {
  width: 100%;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.piclist > li > .delbtn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1px solid #aaa;
  border-radius: 10px;
  padding: 3px 5px;
  cursor: pointer;
}
.piclist > li > .title {
  line-height: 20px;
  font-weight: bold;
}
</style>