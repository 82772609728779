<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-08-09 10:49:28
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-03-07 15:24:27
-->
<template>
  <div>
    <div class="maintitle">版块管理员设置</div>
    <el-divider></el-divider>
    <el-row :gutter='20'>
      <el-col :span='6' class="text-right">版块</el-col>
      <el-col :span="18" class="text-left">
        <el-select v-model='section' class='margin-right' size='mini' placeholder="请选择版块" @change='getManage'>
          <el-option v-for="(item) in sectionlist" :key='item.id' :value='item.name' :label='item.title'></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter='20' v-if='section!=""'>
      <el-col :span='6' class="text-right">管理员</el-col>
      <el-col :span="18" class="text-left">
        <el-select v-model='manage' class='margin-right' style='width:500px' size='mini' placeholder="请选择管理员" filterable multiple>
          <el-option v-for="(item) in employeelist" :key='item.uuid' :value='item.uuid' :label='item.department?item.name+"("+item.department+")":item.name'></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-button type="primary" @click="subForm">设置</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sectionlist: [],
      employeelist: [],
      section: "",
      manage: "",
    };
  },
  methods: {
    getinfo() {
      this.$axios({
        url: this.HOST + "/admin/setting/getSection",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.status == 1) {
          this.sectionlist = res.data.sectionlist;
          this.employeelist = res.data.employeelist;
        }
      });
    },
    subForm() {
      this.$axios({
        url: this.HOST + "/admin/setting/setManage",
        method: "post",
        data: {
          section: this.section,
          manage: this.manage,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            showClose: true,
            type: "success",
            message: res.data.info,
          });
        }
      });
    },
    getManage() {
      this.$axios({
        url: this.HOST + "/admin/setting/getManage",
        method: "post",
        data: {
          section: this.section,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.manage = res.data.manage;
        }
      });
    },
  },
  created: function () {
    this.getinfo();
  },
};
</script>