<template>
  <div>
    <div class="maintitle">菜单列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-button type="success" size="mini" @click="addDiv = true">添加新菜单</el-button>
      <el-button type="success" size="mini" @click="saveSort">保存排序</el-button>
    </el-row>
    <el-table :data="list" row-key="menuid" border :tree-props="{ children: 'children'}">
      <el-table-column prop="title" label="菜单名称"></el-table-column>
      <el-table-column prop="controller" label="控制器名称"></el-table-column>
      <el-table-column prop="action" label="方法名称"></el-table-column>
      <el-table-column label="状态" width="60">
        <template slot-scope="scope">
          <span :class='scope.row.status==1?"text-success":"text-danger"'>{{formatstatus(scope.row.status)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="显示" width="60">
        <template slot-scope="scope">
          <span :class='scope.row.isshow==1?"text-success":"text-danger"'>{{formatshow(scope.row.isshow)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="400" align="center">
        <template slot-scope="scope">
          <el-button type='text' @click="mdfy(scope)">修改</el-button>
          <el-button type='text' @click="setStatus(scope)" class="margin-small-right">{{scope.row.status==1?'停用':'启用'}}</el-button>
          <el-button type='text' @click="addchild(scope)" class="margin-small-right" v-if='scope.row.pid==0'>添加子菜单</el-button>
          <el-button type='text' @click="del(scope)" class="margin-small-right">删除菜单</el-button>
          <el-button type='text' @click="movePre(scope)" class="margin-small-right">上移</el-button>
          <el-button type='text' @click="moveNext(scope)" class="margin-small-right">下移</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="addDiv" :show-close="false" @closed="resetFormInfo" :close-on-click-modal="false">
      <el-form :model="menuinfo">
        <el-form-item prop="menuid" class="hidden">
          <el-input v-model="menuinfo.menuid" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="父菜单" :label-width="formLabelWidth" class="text-left" prop="pid">
          <el-select v-model="menuinfo.pid" placeholder="请选择">
            <el-option key="-1" label="顶级菜单" :value="0"></el-option>
            <el-option v-for="(item, index) in list" :key="index" :label="item.title" :value="item.menuid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单名称" :label-width="formLabelWidth" prop="title">
          <el-input v-model="menuinfo.title" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="控制器" :label-width="formLabelWidth" prop="controller">
          <el-input v-model="menuinfo.controller" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="方法" :label-width="formLabelWidth" prop="action">
          <el-input v-model="menuinfo.action" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="菜单显示" class="text-left" prop="isshow">
          <el-radio-group v-model="menuinfo.isshow">
            <el-radio-button label="1">显示</el-radio-button>
            <el-radio-button label="0">不显示</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="状态" class="text-left" prop="status">
          <el-radio-group v-model="menuinfo.status">
            <el-radio-button label="1">正常</el-radio-button>
            <el-radio-button label="0">停用</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="需要登录" class="text-left" prop="requestLogin">
          <el-radio-group v-model="menuinfo.requestlogin">
            <el-radio-button label="1">需要</el-radio-button>
            <el-radio-button label="0">不需要</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDiv = false;">取 消</el-button>
        <el-button type="primary" @click="submdfy">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //菜单列表
      addDiv: false,
      menuinfo: {
        menuid: 0,
        title: "",
        pid: 0,
        controller: "",
        action: "",
        status: 1,
        isshow: 0,
        requestlogin: 1,
        sort: 0,
      },
      crntscope: "",
      cnrtMenuList: [],
      tempinfo: {},
      section: sessionStorage.getItem('section'),
    };
  },
  methods: {
    getlist() {
      this.$axios({
        url: this.HOST + "/admin/menu/getAllList",
        method: "post",
        data: {
          section: this.section
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.list;
        }
      });
    },
    getBrother(list, id) {
      if (this.cnrtMenuList.length > 0) return;
      if (id == 0) {
        this.cnrtMenuList = list;
        return;
      }
      for (let item in list) {
        if (list[item].menuid == id) {
          this.cnrtMenuList = list[item].children;
        } else if (list[item].children) {
          this.getBrother(list[item].children, id);
        }
      }
    },
    movePre(scope) {
      this.cnrtMenuList = [];
      this.getBrother(this.list, scope.row.pid);
      for (let item in this.cnrtMenuList) {
        if (this.cnrtMenuList[item].menuid == scope.row.menuid) {
          item = parseInt(item);
          if (item == 0) return;
          let temp = this.cnrtMenuList.splice(item, 1);
          this.cnrtMenuList.splice(item - 1, 0, temp[0]);
          return;
        }
      }
    },
    moveNext(scope) {
      this.cnrtMenuList = [];
      this.getBrother(this.list, scope.row.pid);
      for (let item in this.cnrtMenuList) {
        if (this.cnrtMenuList[item].menuid == scope.row.menuid) {
          item = parseInt(item);
          if (item == this.cnrtMenuList.length - 1) return;
          let temp = this.cnrtMenuList.splice(item, 1);
          this.cnrtMenuList.splice(item + 1, 0, temp[0]);
          return;
        }
      }
    },
    initinfo() {
      if (this.crntscope != "") {
        for (let item in this.menuinfo) {
          if (item == 'path') {
            this.menuinfo.path = this.crntscope.row.pid == 0 ? [0] : this.crntscope.row.pid;
          } else {
            this.menuinfo[item] = this.crntscope.row[item];
          }
        }
      } else {
        this.menuinfo.path = this.crntpid ? this.crntpid : [0];
      }
    },
    resetFormInfo() {
      for (let i in this.menuinfo) {
        this.menuinfo[i] = this.tempinfo[i]
      }
    },
    formatstatus(status) {
      return status == 1 ? "正常" : "停用";
    },
    formatshow(status) {
      return status == 1 ? "显示" : "隐藏";
    },
    mdfy(scope) {
      this.crntscope = scope;
      for (let i in this.menuinfo) {
        this.menuinfo[i] = scope.row[i];
      }
      this.addDiv = true;
    },
    addchild(scope) {
      this.menuinfo.pid = scope.row.menuid;
      this.addDiv = true;
    },
    submdfy() {
      this.$axios({
        url: this.HOST + "/admin/menu/saveMenu",
        method: "post",
        data: {
          info: this.menuinfo,
          section: this.section
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.addDiv = false;
          this.getlist();
        }
      });
    },
    del(scope) {
      this.$confirm(
        '确定要删除菜单"' +
        scope.row.title +
        '"吗？本操作会同步删除子菜单并且不可恢复！！！',
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/admin/menu/delMenu",
          method: "post",
          data: {
            id: scope.row.menuid,
            section: this.section
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.getlist();
            this.$message({
              showClose: true,
              type: "info",
              message: "操作成功",
            });
          }
        });
      });
    },
    saveSort() {
      this.$axios({
        url: this.HOST + '/admin/menu/saveSort',
        method: 'post',
        data: {
          list: this.list,
          section: this.section
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.$message({
            showClose: true,
            type: 'success',
            message: res.data.info
          })
          this.getlist();
        }
      })
    },
    setStatus(scope) {
      let status = scope.row.status == 1 ? 0 : 1;
      this.$axios({
        url: this.HOST + "/admin/menu/setStatus",
        method: "post",
        data: {
          info: {
            menuid: scope.row.menuid,
            status: status,
          },
          section: this.section
        },
      }).then((res) => {
        if (res.data.status == 1) {
          scope.row.status = status;
        }
      });
    },
  },
  created: function () {
    this.getlist();
    for (let i in this.menuinfo) {
      this.tempinfo[i] = this.menuinfo[i];
    }
  },
};
</script>