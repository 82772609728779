<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-08-09 15:26:33
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-01-13 16:55:50
-->
<template>
  <div>
    <div class="maintitle">操作员登录记录</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-col :span='6'>
        <el-input v-model="keyword" size='mini' placeholder="请输入搜索关键字"></el-input>
      </el-col>
      <el-date-picker v-model="timerange" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" style="width:250px;" class="margin-big-left" @change="page=1" size="mini"></el-date-picker>
      <el-button @click="getlist" size="mini" class="margin-big-left">搜索</el-button>
    </el-row>
    <el-table :data="list" row-key="menuid" border>
      <el-table-column prop="account" label="账号"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="time" label="登录时间"></el-table-column>
      <el-table-column prop="ip" label="登录ip"></el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //操作员登录记录
      page: 1,
      total: 0,
      timerange: [this.getDate('start'), this.getDate()],
      keyword: ''
    };
  },
  methods: {
    getlist() {
      this.$axios({
        url: this.HOST + "/admin/record/getLogList",
        method: "post",
        data: {
          page: this.page,
          pagesize: this.pagesize,
          keyword: this.keyword,
          timerange: this.timerange
        }
      }).then(res => {
        this.list = res.data.list.data;
        this.total = res.data.list.total;
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
  },
  created: function () {
    this.getlist();
  }
};
</script>