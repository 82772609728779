<template>
  <div>
    <div class="maintitle">年假规则</div>
    <el-divider></el-divider>
    <el-row class="text-left" :gutter="10">
      <el-button class="margin-right" size="mini" @click="getlist">查找</el-button>
      <el-button class="margin-right" size="mini" @click="addDiv=true">添加规则</el-button>
    </el-row>
    <el-table :data="list" row-key="id" border>
      <el-table-column prop="years" label="年数"></el-table-column>
      <el-table-column prop="days" label="天数"></el-table-column>
      <el-table-column label="操作" width='280'>
        <template slot-scope="scope">
          <el-button type='text' class="margin-right" @click="mdfy(scope)">修改</el-button>
          <el-button type='text' class="margin-right" @click="del(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    
    <el-dialog :visible.sync="addDiv" :show-close="false" :close-on-click-modal="false" @closed="resetFormInfo">
      <el-form :model="info">
        <el-form-item label="年数" :label-width="formLabelWidth">
          <el-input v-model="info.years" type="number" min='1' step='1' auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="天数" :label-width="formLabelWidth">
          <el-input v-model="info.days" type="number" min='1' step='1' auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDiv = false">取 消</el-button>
        <el-button type="primary" @click="submdfy">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //员工列表
      addDiv: false,
      info: {
        id: "",
        years: "",
        days: "",
      },
      tempInfo:{},
      crntscope: "",
    };
  },
  computed: {},
  methods: {
    getlist() {
      this.$axios({
        url: this.HOST + "/admin/setting/getAnnualLeaveList",
        method: "post",
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list;
        }
      });
    },
    //重置菜单编辑对话框内容
    resetFormInfo() {
      for (let i in this.info) {
        this.info[i] = this.tempInfo[i];
      }
      this.crntscope = "";
    },
    mdfy(scope) {
      this.crntscope = scope;
      for(let i in this.info){
        this.info[i] = scope.row[i];
      }
      this.addDiv = true;
    },
    submdfy() { 
      this.$axios({
        url: this.HOST + "/admin/setting/saveAnnualLeave",
        method: "post",
        data: {
          info: this.info
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.addDiv = false;
          this.getlist();
        }
      });
    },
    del(scope){
      this.$confirm(
        '确定要删除年假规则吗？',
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/admin/setting/delAnnualLeave",
          method: "post",
          data: {
            id: scope.row.id,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.list.splice(scope.$index,1);
            this.$message({
              showClose: true,
              type: "info",
              message: "操作成功",
            });
          }
        });
      });
    },
  },
  created: function () {
    this.getlist();
    for (let i in this.info) {
      this.tempInfo[i] = this.info[i];
    }
  }
};
</script>