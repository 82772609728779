<template>
  <div>
    <div class="maintitle">部门列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-button type="success" size="mini" @click="addDiv = true">添加新部门</el-button>
      <el-button type="success" size="mini" @click="saveSort">保存排序</el-button>
    </el-row>
    <el-table :data="list" row-key="value" border :tree-props="{ children: 'children',}">
      <el-table-column prop="label" label="部门名称"></el-table-column>
      <el-table-column label="状态" width="60">
        <template slot-scope="scope">
          <span :class='scope.row.status==1?"text-success":"text-danger"'>{{formatstatus(scope.row.status)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="400" align="center">
        <template slot-scope="scope">
          <el-button type='text' @click="mdfy(scope)">修改</el-button>
          <el-button type='text' @click="setStatus(scope)" class="margin-small-right">{{scope.row.status==1?'停用':'启用'}}</el-button>
          <el-button type='text' @click="addchild(scope)" class="margin-small-right">添加子部门</el-button>
          <el-button type='text' @click="del(scope)" class="margin-small-right">删除部门</el-button>
          <el-button type='text' @click="movePre(scope)" class="margin-small-right">上移</el-button>
          <el-button type='text' @click="moveNext(scope)" class="margin-small-right">下移</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="addDiv" :show-close="false" @closed="resetFormInfo" :close-on-click-modal="false" @opened="initinfo">
      <el-form :model="departmentInfo" ref="departmentInfo">
        <el-form-item label="上级部门" :label-width="formLabelWidth" class="text-left">
          <div class="block">
            <el-cascader v-model="departmentInfo.path" :props="{ checkStrictly: true }" :show-all-levels="false" :options="list"></el-cascader>
          </div>
        </el-form-item>
        <el-form-item label="部门名称" :label-width="formLabelWidth">
          <el-input v-model="departmentInfo.label" auto-complete="off" @keyup.enter.native="submdfy"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDiv = false;">取 消</el-button>
        <el-button type="primary" @click="submdfy">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //部门列表
      addDiv: false,
      departmentInfo: {
        value: 0,
        label: "",
        // status: 1,
        path: '',
      },
      crntpid: '',
      crntscope: "",
      crntDepList:[],
      showchildrendiv: false,
    };
  },
  methods: {
    getlist() {
      this.$axios({
        url: this.HOST + "/admin/department/getAllList",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.list;
        }
      });
    },
    getBrother(list,id){ 
      if(this.crntDepList.length>0) return; 
      if(id==0){
        this.crntDepList = list;
        return;
      }
      for (let item in list) {
        if (list[item].value == id) { 
          this.crntDepList = list[item].children;
        } else if (list[item].children) {
          this.getBrother(list[item].children, id);
        }
      }
    },
    formatstatus(status) {
      return status == 1 ? "正常" : "停用";
    },
    setStatus(scope) {
      let status = scope.row.status == 1 ? 0 : 1;
      this.$axios({
        url: this.HOST + "/admin/department/setStatus",
        method: "post",
        data: {
          info: {
            id: scope.row.value,
            status: status,
          },
        },
      }).then((res) => {
        if (res.data.status == 1) {
          scope.row.status = status;
        }
      });
    },
    //重置部门编辑对话框内容
    resetFormInfo() {
      this.crntscope = "";
      this.crntpid = "";
      this.departmentInfo = {
        value: 0,
        label: "",
        // status: 1,
        sort: 0,
        path: '',
      }
    },
    mdfy(scope) {
      this.crntscope = scope;
      this.addDiv = true;
    },
    movePre(scope) {
      this.crntDepList = [];
      this.getBrother(this.list,scope.row.pid);
      for (let item in this.crntDepList) {
        if (this.crntDepList[item].value == scope.row.value) {
          item = parseInt(item);
          if (item == 0) return;
          let temp = this.crntDepList.splice(item, 1);
          this.crntDepList.splice(item - 1, 0, temp[0]);
          return;
        }
      }
    },
    moveNext(scope) {
      this.crntDepList = [];
      this.getBrother(this.list,scope.row.pid);
      for (let item in this.crntDepList) {
        if (this.crntDepList[item].value == scope.row.value) {
          item = parseInt(item);
          if (item == this.crntDepList.length - 1) return;
          let temp = this.crntDepList.splice(item, 1);
          this.crntDepList.splice(item + 1, 0, temp[0]);
          return;
        }
      }
    },
    initinfo() {
      if (this.crntscope != "") {
        for (let item in this.departmentInfo) {
          if (item == 'path') {
            this.departmentInfo.path = this.crntscope.row.pid == 0 ? [0] : this.crntscope.row.pid;
          } else {
            this.departmentInfo[item] = this.crntscope.row[item];
          }
        }
      } else {
        this.departmentInfo.path = this.crntpid ? this.crntpid : [0];
      }
    },
    addchild(scope) {
      this.crntpid = scope.row.value;
      this.addDiv = true;
    },
    submdfy() {
      this.$axios({
        url: this.HOST + "/admin/department/saveDepartment",
        method: "post",
        data: {
          info: this.departmentInfo,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.addDiv = false;
          this.getlist();
        }
      });
    },
    del(scope) {
      this.$confirm(
        '确定要删除部门"' +
        scope.row.label +
        '"吗？本操作会同步操作子部门并且不可恢复！！！',
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/admin/department/del",
          method: "post",
          data: {
            id: scope.row.value,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.getlist();
            this.$message({
              showClose: true,
              type: "info",
              message: "操作成功",
            });
          }
        });
      });
    },
    saveSort() {
      this.$axios({
        url: this.HOST + '/admin/department/saveSort',
        method: 'post',
        data: {
          list: this.list
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.$message({
              showClose: true,
            type: 'success',
            message: res.data.info
          })
          this.getlist();
        }
      })
    },
  },
  created: function () {
    this.getlist();
  },
};
</script>